import React, { useMemo } from "react";
import { SortableContainer, SortableElement, SortEnd } from "react-sortable-hoc";
import { observer } from "mobx-react-lite";
import { Button, Nullable, SvgIcon, clsx, globalStyles } from "@gemlightbox/core-kit";

import { PendingUploadStatus, ProductImageModel } from "src/models";
import { useStores } from "src/hooks";
import { MAX_MEDIA_IN_PRODUCT } from "src/containers/products";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { ProductPreviewMedia } from "./product-preview-media";

import { ReactComponent as BlinkSVG } from "src/external-ts/assets/images/media/blink-type.svg";
import { ReactComponent as PlusSVG } from "src/external-ts/assets/images/plus-circle-filled-grey.svg";
import styles from "./product-media-bar.module.css";

export type ProductMediaBarProps = {
  productMedias: ProductImageModel[];
  onAddMediaClick: VoidFunction;
  onRemoveMediaClick: (media: ProductImageModel) => void;
  onMediaClick: (media: ProductImageModel) => void;
  onMediaDrag: (newMedias: ProductImageModel[]) => void;
  productId?: Nullable<number>;
  onBlinkClick?: VoidFunction;
  productImage?: Nullable<ProductImageModel>;
};

export const ProductMediasBar: React.FC<ProductMediaBarProps> = observer(
  ({
    productMedias,
    onAddMediaClick,
    onRemoveMediaClick,
    onMediaClick,
    onMediaDrag,
    productId,
    onBlinkClick,
    productImage,
  }: ProductMediaBarProps) => {
    const { localeStore, notificationStore, productsStore } = useStores();

    const { canBlinks } = useLimits();

    const canShowBlink = useMemo(() => {
      const alreadyHasBlink = productMedias.find((media) => media.type === "blink");
      const hasImages = productMedias.some(
        (media) => media.type === "image" && media?.status === PendingUploadStatus.complete,
      );
      return (
        onBlinkClick &&
        canBlinks &&
        !alreadyHasBlink &&
        hasImages &&
        productMedias.length < MAX_MEDIA_IN_PRODUCT
      );
    }, [onBlinkClick, productMedias, canBlinks]);

    const updateMediaOrder = async (medias: ProductImageModel[]) => {
      const mediaIDS = medias.map((media) => media.id);
      if (productId) await productsStore.updateProduct(productId, { mediaIDS });
    };

    const handleSortEnd = async ({ oldIndex, newIndex }: SortEnd) => {
      const newMedias = productMedias.slice();
      if (oldIndex === newIndex) {
        return newMedias;
      }

      const element = newMedias[oldIndex];
      newMedias.splice(oldIndex, 1);
      newMedias.splice(newIndex, 0, element);
      onMediaDrag(newMedias);
      await updateMediaOrder(newMedias);
    };

    const handleRemoveMediaClick = (media: ProductImageModel) => {
      notificationStore.open({
        title: localeStore.t(
          'components.pages.product["product-media-bar"]["confirm-media-remove"].title',
        ),
        message: localeStore.t(
          'components.pages.product["product-media-bar"]["confirm-media-remove"].message',
        ),
        confirmText: localeStore.t(
          'components.pages.product["product-media-bar"]["confirm-media-remove"].dissociate',
        ),
        cancelText: localeStore.t("common.buttons.cancel"),
        onOk: async () => {
          await onRemoveMediaClick(media);
        },
      });
    };

    const DraggableMedia = SortableElement(({ item }: { item: ProductImageModel }) => (
      <ProductPreviewMedia
        productImage={item}
        onDeleteClick={handleRemoveMediaClick}
        onMediaClick={onMediaClick}
        action={item.id === productImage?.id}
      />
    ));

    const DroppableMediaList = SortableContainer(({ items }: { items: ProductImageModel[] }) => {
      return (
        <div className={clsx(styles.productMediaBarContainer, globalStyles.addScrollStyles)}>
          {canShowBlink && (
            <Button
              className={styles.blinkButton}
              appearance="tertiaryOutlined"
              onClick={onBlinkClick}
              data-cy="create-model-image"
            >
              <SvgIcon icon={BlinkSVG} disableAutoColor />
              {localeStore.t('common.media["model-image"]')}
            </Button>
          )}

          {items.map((media, index) => (
            <DraggableMedia key={media.id} index={index} item={media} />
          ))}

          {items.length < MAX_MEDIA_IN_PRODUCT && (
            <Button appearance="secondary" onClick={onAddMediaClick} data-cy="add-media-to-product">
              <SvgIcon icon={PlusSVG} />
            </Button>
          )}
        </div>
      );
    });

    if (!productMedias.length) return null;

    return (
      <DroppableMediaList
        axis="xy"
        distance={5}
        items={productMedias}
        onSortEnd={handleSortEnd}
        useDragHandle
      />
    );
  },
);

export default ProductMediasBar;
