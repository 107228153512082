import React, { useRef, useState } from "react";
import moment from "moment";
import { observer } from "mobx-react-lite";
import {
  Button,
  clsx,
  Heading,
  Loader,
  NativeInteractiveVideo,
  Nullable,
  ProgressCircle,
  SvgIcon,
  Typography,
  useCall,
  useDidMount,
  Video,
  useDebounce,
  Image,
  useBoolean,
} from "@gemlightbox/core-kit";

import { ENVIRONMENT } from "src/config/environment";
import WSConnection from "src/common/socket";
import { MediaModel, PendingUploadStatus } from "src/models";
import { ErrorCodes } from "src/constants";
import { getUserTagManagerInfoCallback, postBatchRemoveBackground } from "src/api";
import { useStores } from "src/hooks";
import { getMediaSrc, proFeatureTooltip, pushDataLayerEvent } from "src/utils";
import { openBackgroundRemovalErrorNotification } from "src/containers/media/media.utils";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { MediaTags } from "src/containers/media/media-tags";

import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-thin-grey.svg";
import { ReactComponent as EditSVG } from "src/external-ts/assets/images/pencil-grey.svg";
import { ReactComponent as EyeSVG } from "src/external-ts/assets/images/eye-grey.svg";
import { ReactComponent as NewBadgeSVG } from "src/external-ts/assets/images/badges/new-badge.svg";
import { ReactComponent as ErrorSVG } from "src/external-ts/assets/images/circle-warning-icon-red.svg";
import { ReactComponent as PendingUpSVG } from "src/external-ts/assets/images/pending-upload-icon.svg";
import { ReactComponent as RemoveBackgroundSVG } from "src/external-ts/assets/images/edit-media/background-icon.svg";
import styles from "./media-item-view.module.css";
import { ProUserUpgradeModal } from "src/external-ts/components";

export const colorsArr = ["transparent", "white", "black"];

const devEnvironment = ENVIRONMENT !== "production" && ENVIRONMENT !== "staging";

type MediaItemViewProps = {
  image: Nullable<HTMLImageElement>;
  media: MediaModel;
  onEdit: VoidFunction;
  closeSidebar: VoidFunction;
};

export const MediaItemView: React.FC<MediaItemViewProps> = observer(
  ({ image, media, onEdit, closeSidebar }) => {
    const debounce = useDebounce(3000);

    const { canBlinks } = useLimits();

    const {
      mediaStore,
      userStore,
      localeStore,
      notificationStore,
      tooltipStore,
      subscriptionsStore,
    } = useStores();

    const requestIdRef = useRef("");
    const removeBGIdRef = useRef(0);

    const [tempSrc, setTempSrc] = useState("");
    const [isInEditColor, setIsInEditColor] = useState(false);
    const [error, setError] = useState(false);
    const [progress, setProgress] = useState(0);
    const [selectedEditColor, setSelectedEditColor] = useState("white");
    const [proUserUpgradeContent, setProUserUpgradeContent] = useState("");
    const proUserUpgradeBool = useBoolean(false);
    const [metaData, setMetaData] = useState(media.metaData);

    const removeBackgroundCall = useCall(postBatchRemoveBackground);
    removeBackgroundCall.onCallStart(({ headers }) => {
      setProgress(0);
      requestIdRef.current = headers["x-request-id"];
    });
    removeBackgroundCall.onCallSuccess(async (data) => {
      const link = data.links?.[0];
      if (!link) return;

      // Note: silently updating userMe due to usage limits
      userStore.loadUserMeSilently();

      // Note: pushing event to google tag
      getUserTagManagerInfoCallback((response) => {
        pushDataLayerEvent({
          event: "remove_bg_ai",
          user_id: response.user_id,
          account_type: response.account_type,
          is_trial: response.isTrial,
        });
      });

      removeBGIdRef.current = data.id;
      setTempSrc(link);
      setIsInEditColor(true);
    });
    removeBackgroundCall.onCallError(({ originalError }) => {
      if (originalError.code === ErrorCodes.MEDIA_BACKGROUND_REMOVE_LIMIT_EXCEEDED) {
        openBackgroundRemovalErrorNotification(originalError.message);
      }

      setError(true);
      debounce.debounce(() => setError(false));
    });

    const src = tempSrc || getMediaSrc(media, "medium");
    const isUploading = media?.status === PendingUploadStatus.pending;
    const isVideo = media.type === "video" && !!src;
    const isVideo360 = media.type === "video360" && !!src;
    const isVideoType = isVideo || isVideo360;
    const isImage = media.type === "image" && !!src;
    const isBlink = media.type === "blink" && !!src;
    const removingBG = removeBackgroundCall.submitting;
    const showLoader = isImage && !isUploading && !image;

    const canShowRemoveBG =
      !removingBG &&
      !isInEditColor &&
      isImage &&
      !metaData?.isAiBackgroundRemoval &&
      !metaData?.isAiRetouch &&
      !isUploading;

    // NOTE: Using 'media?.arData?.arTemplateId' due to not all blinks are editable, but only one's,
    // that has necessary fields
    const canShowEditMediaBlink = isBlink && !!media.arTemplateId;
    const canShowEditMediaImage = isImage;
    const canShowEdit = canShowEditMediaBlink || canShowEditMediaImage;

    const isEditDisabled = isBlink && !canBlinks;

    const handleEditPointerEnter = (e: React.PointerEvent<HTMLButtonElement>) => {
      if (!isEditDisabled) return;
      proFeatureTooltip(e.currentTarget, {
        position: "left",
        onUpgradeClick: closeSidebar,
      });
    };
    const handleEditPointerLeave = () => {
      if (!isEditDisabled) tooltipStore.close();
    };
    const handleEditClick = () => onEdit();

    const handleRemoveBG = (color = "white") => {
      const formData = new FormData();
      formData.append("background_color", color);
      formData.append("media_ids", media.id.toString());
      removeBackgroundCall.submit({ data: formData });

      setSelectedEditColor(color);
    };

    const handleRemoveBGClick = () => {
      if (
        !userStore.isSubscribed &&
        (userStore.userUsage?.countBackgroundRemoval ?? 0) >=
          subscriptionsStore.limits.count_removal_background
      ) {
        setProUserUpgradeContent(localeStore.t('common["pro-user"]["remove-bg-upgrade-text"]'));
        proUserUpgradeBool.setTruthy();
        return;
      }
      handleRemoveBG();
    };

    const handleColorChange = (color: string) => () => handleRemoveBG(color);

    const handleCancelEdit = () => {
      removeBackgroundCall.abortCall();
      debounce.resetDebounce();
      setTempSrc("");
      setError(false);
      setIsInEditColor(false);
    };

    const handleSaveImage = async (asNew: boolean) => {
      if (!removeBGIdRef.current) return;
      const result = await mediaStore.putSaveAIResult(removeBGIdRef.current, asNew);

      if (result.success) {
        handleCancelEdit();
        if (result.success.rows && Array.isArray(result.success.rows))
          setMetaData((result.success.rows[0] as MediaModel).metaData);

        if (asNew) {
          closeSidebar();
        } else {
          // NOTE: 'handleCancelEdit()' makes 'setTempSrc("")', but is asNew === 'false', save prev selected color, so to not make overhead logic.
          setTempSrc(tempSrc);
        }
      }
    };

    const handleSaveImageClick = () => {
      notificationStore.open({
        title: localeStore.t('common.media["how-to-save"]'),
        icon: "questionMarkRoundedFilled",
        confirmAppearance: "primary",
        confirmText: localeStore.t('common.buttons["save-changes"]'),
        cancelText: localeStore.t('common.media["save-as-new"]'),
        onOk: () => handleSaveImage(false),
        onCancel: () => handleSaveImage(true),
      });
    };

    useDidMount(() => {
      const userId = userStore.userMe?.user._id;

      if (!userId) return;

      const progressWS = new WSConnection(`/user/${userId}`, userStore.token);

      progressWS.on("progress/UPDATE", ({ payload }: any) => {
        if (requestIdRef.current === payload.requestID) {
          setProgress(payload.progress);
        }
      });

      return () => {
        progressWS.destroy();
      };
    });

    if (!media) return null;

    return (
      <div className={styles.container}>
        <div className={styles.view}>
          {showLoader && <Loader position="absolute" withOverlay />}

          {isVideo && (
            <Video
              src={src}
              className={styles.videoContainer}
              videoClassName={styles.videoContent}
            />
          )}
          {isVideo360 && (
            <NativeInteractiveVideo className={styles.interactiveVideoContainer} src={src} />
          )}
          {(isImage || isBlink) && (
            <Image
              key={src} // NOTE: pass src as key, so no flicks will be visible due to cache
              className={styles.imageContainer}
              src={src}
              objectFit="contain"
              loading="eager"
            />
          )}

          {isUploading && (
            <div className={styles.pendingUpWrapper}>
              <SvgIcon icon={PendingUpSVG} size={128} />
              <span>
                {localeStore
                  .t('media.modals["pending-upload-media-sidebar"]["pending-up-from"]')
                  .replace(
                    localeStore.t('media.modals["pending-upload-media-sidebar"]["unknown-source"]'),
                    media?.metaData?.deviceName ||
                      localeStore.t(
                        'media.modals["pending-upload-media-sidebar"]["unknown-source"]',
                      ),
                  )}
              </span>
            </div>
          )}

          {error && !removingBG && (
            <div className={styles.messageContainer}>
              <SvgIcon icon={ErrorSVG} size={64} />
              <Heading tag="h2">
                {localeStore.t('media.modals["media-details-sidebar"]["media-item-view"].error')}
              </Heading>
            </div>
          )}

          {removingBG && (
            <div className={styles.messageContainer}>
              <ProgressCircle progress={progress} />
            </div>
          )}

          {isInEditColor && !removingBG && (
            <div
              className={clsx(styles.editColorsWrapper, { [styles.videoMediaType]: isVideoType })}
            >
              {colorsArr.map((color) => (
                <Button
                  key={color}
                  className={styles.colorBtn}
                  appearance="tertiaryOutlined"
                  size="small"
                  style={{ backgroundColor: color }}
                  onClick={handleColorChange(color)}
                >
                  {selectedEditColor === color && (
                    <div className={styles.colorItemOverlay}>
                      <SvgIcon icon={CheckmarkSVG} size={16} />
                    </div>
                  )}
                </Button>
              ))}

              {!isVideoType && (
                <Button appearance="primaryOutlined" size="small" onClick={handleEditClick}>
                  <SvgIcon icon={EditSVG} />
                </Button>
              )}

              <Button
                className={styles.cancelColorBtn}
                appearance="tertiaryOutlined"
                size="small"
                onClick={handleCancelEdit}
              >
                {localeStore.t("common.buttons.cancel")}
              </Button>
              <Button className={styles.saveColorBtn} size="small" onClick={handleSaveImageClick}>
                {localeStore.t("common.buttons.save")}
              </Button>
            </div>
          )}

          {canShowEdit && !isInEditColor && (
            <Button
              className={styles.editBtn}
              appearance="tertiaryOutlined"
              onPointerEnter={handleEditPointerEnter}
              onPointerLeave={handleEditPointerLeave}
              onClick={handleEditClick}
              disabled={isEditDisabled}
            >
              <SvgIcon icon={EditSVG} />
              {localeStore.t('common.media["edit-media"]')}
            </Button>
          )}

          {!isInEditColor && (
            <MediaTags
              className={clsx(styles.mediaTags, { [styles.backgroundBtnVideo]: isVideoType })}
              metaData={metaData}
              mediaType={media.type}
              isShowInfo={true}
              isCreatedByMobile={!media?.arTemplateId}
            />
          )}

          {canShowRemoveBG && (
            <Button
              className={clsx(styles.backgroundBtn, { [styles.backgroundBtnVideo]: isVideoType })}
              appearance="primaryOutlined"
              onClick={handleRemoveBGClick}
            >
              <SvgIcon className={styles.betaBadge} icon={NewBadgeSVG} size={[30, 12]} />
              <SvgIcon icon={RemoveBackgroundSVG} size={20} />
              {localeStore.t('common.buttons["remove-background"]')}
              {!userStore.isSubscribed &&
                `(${Math.max(
                  0,
                  subscriptionsStore.limits.count_removal_background -
                    (userStore.userUsage?.countBackgroundRemoval ?? 0),
                )}/${subscriptionsStore.limits.count_removal_background})`}
            </Button>
          )}
        </div>

        <div className={styles.info}>
          <div className={styles.date}>
            <Typography size="small" color="textTertiary">
              {localeStore.t(
                'media.modals["media-details-sidebar"]["media-item-view"]["date-label"]',
              )}
            </Typography>
            <Typography className={styles.dateValue} size="small" color="textSecondary">
              {moment(media.created_at).format("dddd, D MMM YYYY [at] hh:mmA")}
            </Typography>
          </div>
          <div className={styles.views}>
            <SvgIcon icon={EyeSVG} size={16} />
            <Typography size="tiny" color="textSecondary">
              {media.views} views
            </Typography>
          </div>
        </div>

        <ProUserUpgradeModal
          visible={proUserUpgradeBool}
          onClose={() => {
            proUserUpgradeBool.setFalsy();
            closeSidebar();
          }}
          content={proUserUpgradeContent}
        ></ProUserUpgradeModal>
      </div>
    );
  },
);
